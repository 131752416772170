// ********************************
// *
// * Content List Component
// *
// * Has 1 layouts:
// *    Vertical (default) - Simple list of links for use in 2-column-left template
// *
// ********************************

$contentlist_margin: $column-gutter 0 $column-gutter 0 !default;
$contentlist_list_style: none !default;

// Content List styles applied to all nav.contentlist elements
@mixin cms-content-list() {
    nav.contentlist {
        margin: $contentlist_margin;

        ul {
            list-style: $contentlist_list_style;
            padding: 0;
            margin: 0;
        }
    }
}
